const AppStrings = {
    appName: "AI Caring",
    inactivityLogout: 'You have been logged out due to inactivity.',
    ok: 'OK',
}

const PatientCardStrings = {
    level: 'Level',
    lastActivity: 'Last sign of activity',
    notAvailable: 'Not Available',
};

const HomePageStrings = {
    showList: 'Show List',
    needsAttention: 'Needs attention',
    monitoringLevel: 'Monitoring Level',
    all: "All",
    level1: "Level 1" ,
    level2: "Level 2",
    level3: "Level 3",
    disconnected: "Disconnected",
    systemConnected: "System Connected",
    sensorIssue: "Sensor Issue",
    notApplicable: 'N/A',
    agingInPlace: "Aging-in-Place",
    fullName: 'Full Name',
    level: 'Level',
    connection: 'Connection',
    behaviorState: 'Behavior State',
    lastActivity: 'Last Activity',
    notConnected: 'Not Connected',
    numPatients: (patientCount) => {return `There are ${patientCount} patients under your charge.`},
    currentlyNeedsAttn: 'Currently needing attention:',
    needingAttn: 'Needing Attention',
    status: 'Status:',
    normal: 'Normal',
    patientActivityStatus: 'patient activity status',
    systemStatus: 'System Status',
    statusIsGood: 'Patient status is good',
    behavioralStatus: 'Behavioral status',
    environStatus: 'Environmental Status',
    physiologyStatus: 'Physiological status',
    connectionStatus: 'Connection Status',
    tableData: 'Table Data',
    summary: 'Summary',
}

const OptionsPageString = {
    level1: 'Level 1',
    level2: 'Level 2',
    level3: 'Level 3',
    notificationsAndAlerts: 'Notifications and Alerts',
    errorSubmittingOptions: 'Error submitting options',
    done: 'Done',
    communicationSettings: 'Communication Settings',
    submit: 'Submit',
    monitoringLevel: 'Monitoring Levels',
    email: 'Email',
    sms: 'Text',
    dailyReport: 'Daily Report',
    normalcyNotifications: 'Normalcy Notifications',
    outOfPatternAlerts: 'Out-of-Pattern Alerts',
    outOfRangeAlerts: 'Out-of-Range Alerts',
    symptomaticAlerts: 'Symptomatic Alerts',
    morningRiseTime: 'Morning Rise Time',
    unusuallyLateRise: 'Unusually Late Rise',
    unusualInactivity: "Unusual Inactivity",
    houseTemperature: 'House Temperature',
    increasedNighttimeActivity: 'Increased Nighttime Activity',
    unusuallySlowWalkingSpeed: 'Unusually Slow Walking Speed',
    repetitiveActivity: 'Repetitive Activity',
    firstDailyMeal: 'First Daily Meal',
    eveningBedtime: 'Evening Bedtime',
    unusuallyLowActivity: 'Unusually Low Activity',
    unusuallyLateFirstMeal: 'Unusually Late First Meal',
    bloodPressure: 'Blood Pressure',
    bloodOxygenSaturation: 'Blood Oxygen Saturation',
    heartRate: 'Heart Rate',
    glucoseLevels: 'Glucose Levels',

    // advanced options strings
    unsusedLabel: '',
    useEMPYuValue: "Use AI Caring value",

    insufficientActivityTitle: "Insufficent Activity Alert Settings",
    insufficientActivityDescription: "Specify the minimum percentage of average activity this client should perform each day.  You will be alerted if the client has not reached his daily allotment.",
    insufficientActivityLabel1: "Percentage of average activity: ",
    
    oximeterTitle: 'Blood Oxygen Level Alert Settings',
    oximeterDescription1: 
        'AI Caring accepts data from user-selected home medical devices like blood oxygen level monitors.  ' +
        'Authorized caregivers can be alerted when values are outside the defined normal range.',
    oximeterDescription2: 
        'To trigger an alert, select the system default thresholds or enter the appropriate values for your care recipient.',
    oximeterLabel1: 'Minimum blood/oxygen level: ',
    oximeterEMPYuOption: 'AI Caring system defaults (95%)',

    heartRateTitle: 'Unusually Heart Rate Alert Settings',
    heartRateDescription1:
        'AI Caring accepts data from user-selected home medical devices like heart rate monitors.  ' +
        'Authorized caregivers can be alerted when values are outside the defined normal range.',
    heartRateDescription2:
        "To trigger an alert, select the system default thresholds or enter the appropriate values for your care recipient.",
    heartRateLabel1: 'Heart rate is below: ',
    heartRateLabel2: 'Heart rate is above: ',
    heartRateEMPYuOption: '"AI Caring system defaults (90 bpm)',

    slowGaitTitle: "Slow Gait Alert Settings",
    slowGaitDescriptions: "Specify the average slow gait for this client.  You will be alerted if the system detected values below this.",
    slowGaitLabel1: "Average slow gait percentage:",

    nightTimeActivityTitle: "Patient Night Time Activity Alert Settings",
    nightTimeActivityDescriptions: "We monitor the amount of activity a client experiences during the night. Specify a percentage above which you'd like to be notified .",
    nightTimeActivityLabel1: "Maximum night time activity (percentage): ",

    lateRiseTitle: "Late Rise Alert Settings",
    lateRiseDescriptions1: 
        "Over time AI Caring learns when a person tends to rise after sleeping. Choose between the AI Caring value or select one that you think is appropriate.",
    lateRiseDescriptions2: 
        "If the care recipient doesn't rise by this expected time we will notify the caregiver.",
    lateRiseLabel1: "Caregiver specified time for late rise alert: ",
    lateRiseEMPYuOption: "Use AI Caring learned end of 'normal rise time'",

    unusualQuietTitle: "Inactivity Alert Settings",
    unusualQuietDescriptions1: 
        "If a person is at home and there is no detected activity for a defined period of time in which activity " +
        "normally occurs, AI Caring creates an 'Inactivity' alert. This would be consistent with a disabling fall.",
    unusualQuietDescriptions2: 
        "Select the number of hours of inactivity before the system sends an alert.",
    unusualQuietLabel1: "Caregiver chosen period of inactivity (Hours): ",
    unusualQuietEMPYuOption: "System default period of inactivity (Hours): 6",

    bpTitle: "Blood Pressure Alert Settings",
    bpDescriptions1: 
        "AI Caring accepts data from user-selected home medical devices like blood pressure monitors.  " +
        "Authorized caregivers can be alerted when values are outside the defined normal range.",
    bpDescriptions2:
        "To trigger an alert, select the system default thresholds or enter the appropriate values for your care recipient.",
    bpLabel1: "Systolic is above: ",
    bpLabel2: "Diastolic is above: ",
    bpEMPYuOption: "AI Caring system defaults (125/80)",

    houseTempTitle: "Unusual House Temperature Alert Settings",
    houseTempDescriptions: 
        "The AI Caring system takes temperature readings in important rooms like the kitchen and bedroom. " +
        "Use the system's temperature defaults or choose temperatures that are appropriate for your care recipient.",
    houseTempLabel1: "Minimum room temperature: ",
    houseTempLabel2: "Maximum room temperature: ",
    houseTempEMPYuOption: "System default temperature range (Min: 55, Max: 85)",
    
    missedMealTitle: "Unusually Late Meal Alert Settings",
    missedMealDescriptions: "If a Client doesn't eat a meal by this specified time we will notify the caregiver.",
    missedMealLabel1: "Meal considered missed after: ",
    // missedMealEMPYuOption: "AI Caring system defaults (125/80)",

    glucoseTitle: "Glucose Level Alert Settings",
    glucoseDescriptions1: 
        "AI Caring accepts data from user-selected home medical devices like glucose levell monitors. Authorized caregivers can be " +
        "alerted when values are outside the defined normal range.",
    glucoseDescriptions2: 
        "To trigger an alert, select the system default thresholds or enter the appropriate values for your care recipient.",
    glucoseLabel1: "Minimum glucose level: ",
    glucoseLabel2: "Maximum glucose level: ",
    glucoseEMPYuOption: "System default glucose range (Min: 70mg, Max: 130mg)",

    useDefault: "Use Default",
    dayOfWeek: "Day of Week",
    customTime: "Custom Time",
    defaultTime: "Default Time",  
    am: "am",
    pm: "pm",
    dowSun: "Sun", 
    dowMon: "Mon", 
    dowTues: "Tues", 
    dowWed: "Wed", 
    dowThur: "Thur", 
    dowFri: "Fri", 
    dowSat: "Sat",
}
const DetailPageStrings = {
    lastEvent: "Last Event: ",
    sleeping: "Sleeping",
    toBed: "To Bed: ",
    wakeUp: "Wake Up: ",
    meals: "Meals",
    firstToday: "First Today: ",
    mostRecent: "Most Recent: ",
    dailyActivity: "Daily Activity",
    prolongedInactivity: "Prolonged inactivity",
    back: "Back",
    hourly: "Hourly",
    settings: "Settings",
    noProlongedInactivity: "No Prolonged Inactivity...",
    batteryLevels: "Battery Levels",
    homeLife: "Home Life Record",
    validicDevices: "External Connections",
    locAlertDetails: "Location Alert Options",
};

const NavbarStrings = {
    home: "HOME",
    admin: "ADMIN",
    caregiver: "CAREGIVER PROFILE",
    about: "ABOUT",
    logout: "LOGOUT"
};

const NextPrevNavStrings = {
    next: "Next",
    prev: "Previous"
};

const LoginStrings = {
    loginHeader: "Log in to AI Caring",
    username: "Username",
    password: "Password",
    missingUsername: "Empty Username.",
    missingPassword: "Empty Password.",
    badUsernamePassword: "Invalid username or password."
};

const HeatMapStrings = {
    today: "Today",
    yesterday: "Yesterday",
    activity: "Activity",
    normal: "Normal value",
    abnormal: "Abnormal value",
    deviceEvents: "Device Events",
    sleepHeatMap: "Sleep Heat Map",
    mealsHeatMap: "Meals Heat Map",
    activityHeatMap: "Activity Heat Map",
    mealsHeaderContent1: "Meals Visualization",
    mealsHeaderContent2: "Meal preparation times are shown in green. Possible meal preparation is displayed in dark gray",
    sleepingHeaderContent1: "Sleep Visualization",
    sleepingHeaderContent2: "Sleep times are shown in blue. Possible sleep is displayed in dark gray",
    activityHeaderContent1: "Activity Visualization",
    activityHeaderContent2: "Active times are displayed in shades of yellow and orange",
    sleepingTitle: "Sleep",
    mealsTitle: "Meals",
    activityTitle: "Activity",
    lo: "Lo",
    hi: "Hi",
    minutesActive: "minutes active: ",
    threshold: "threshold=",
    totalActivity: "Total Activity = ",
    sleepActivity: ", Sleep Activity = ",
    RxDevGoodValToolTip: "Device reported one or more values within acceptable range",
    RxDevBadValToolTip: "Device reported only values outside of acceptable range",
    noRxData: "Device data is not available"
};

const BatteryLevelStrings = {
    title: "Battery Charge Level for Sensors",
    levelAsOf: "Battery Charge Level as of: ",
    deviceName: "Device Name",
    chargeLevel: "Charge Level",
    lastEvent: "Last Notification"
};

const HomeLifeStrings = {
    title: "Home Life Record",
    sleeping: "Sleeping",
    numHours: "# hours",
    numPeriods: "# wake periods > 3 hrs",
    gapsLong: "# gaps 30 min – 3 hrs",
    gapsShort: "# gaps < 30 mins",
    eating: "Eating",
    meal: "# meals",
    kitchenActivity: "kitchen score",
    activity: "Activity",
    total: "overall score",
    activitiesDuringSleep: "during sleep",
    hrsAwayFromHome: "hours away from home",
    activityHomeWaking: "avg in-home activity",
    activitiesTotalSleepRatio: "day vs night activity ratio",
    homeTemp: "Home Temperature",
    high: "high",
    low: "low",
    medicalDevices: "Medical Devices",
    notAvailable: "-",
    day: 'Day ',
    priorWeek: 'Prior Week',
    priorMonth: 'Prior Month',
    avg: 'avg',
    stDev: 'st. dev',
    oneDPrior: '1day prior',
    twoDPrior: '2day prior',
    threeDPrior: '3day prior',
    fourDPrior: '4day prior',
    fiveDPrior: '5day prior',
    sixDPrior: '6day prior',
    sevenDPrior: '7day prior',
    serverError: `We are sorry. We are experiencing some issues.  Please try again later.`
};

const LocationAlertOptionsStrings = {
    title: "Location Alert Options",
    header: "Click the button to toggle the arm switch for the location alerts.",
    buttonTextOn: "Click to Dis-arm Alerts",
    buttonTextOff: "Click to Arm Alerts"
};

const DayOfWeekStrings = {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
};

const rxEvtReportStrings = {
    rxHeader: "All Medical Device Events",
    // rxHeader: "All Medical Device events for: ",
    date: "Event Time",
    deviceName: "Device Name",
    rxData: "Medical Device Data"
};

const UnusualEvtReportStrings = {
    unusualEvtsHeader: "Recent Unusual Events",
    ueSortedByDate: "Unusual Events sorted by date",
    date: "Event Time and Date",
    description: "Description",
    concern: "",                    // "Concern Level"
};

const DeviceEventReportStrings = {
    viewEvents: "View events:",
    chooseEventTypes: "Choose Device Events to view:",
    basicEvents: "Basic Events",
    detailedEvents: "Detailed Events",
    date: "Date",
    time: "Time",
    sensor: "Sensor",
    event: "Event",
    when: "When",
    deviceId: "DeviceId", 
    description: "Description",
    value: "Value",
    all: "All"
};

const ValidicDeviceStrings = {
    title: 'Connect External Devices',
    welcome: 'Connect Health and Wellness Devices and Apps ',
    explanation: 'Your permission is required to retrieve Health and Wellness  Data from manufacturers such as ' +
        'BodyTrace, Fitbit, iHealth, Withings, etc. ',
    instruct: 'Please click on the button below to proceed to the authentication and permission process.',
    buttonText: 'Connect',
};

const HeaderFooterStrings = {
    // copyright: " Copyright 2017-2024 EmPowerYu, Inc.",
    copyright: " ",
    // rights: "  All Rights Reserved",
    rights: "  ",
    companyName: "AI Caring"
};
export {
    AppStrings,
    DayOfWeekStrings,
    DetailPageStrings,
    NavbarStrings,
    LoginStrings,
    HeatMapStrings,
    HeaderFooterStrings,
    rxEvtReportStrings,
    UnusualEvtReportStrings,
    DeviceEventReportStrings,
    NextPrevNavStrings,
    BatteryLevelStrings,
    PatientCardStrings,
    HomeLifeStrings,
    ValidicDeviceStrings,
    HomePageStrings,
    OptionsPageString,
    LocationAlertOptionsStrings
};
